<template>
  <div class="customer-payer">
    <div class="customer-payer-title">
      <van-row class="info-title">客户货量详情信息</van-row>
    </div>
    <div class="customer-payer-choose">
      <van-row>
        <van-col span="24">
          <van-cell-group>
            <van-field v-model="customerName" label="客户"
                       right-icon="manager-o"
                       placeholder="请输入客户名称"/>
          </van-cell-group>
        </van-col>
      </van-row>
      <van-row>
        <van-col span="24">
          <van-cell-group>
            <van-field
                readonly
                clickable
                label="客户群组"
                :value="customerGroup"
                placeholder="请选择"
                input-align="left"
                right-icon="friends-o"
                @click="onClickGroup()"
            />
            <van-popup v-model="showGroupPicker" round position="bottom">
              <van-picker
                  show-toolbar
                  :columns="columnsGroup"
                  @cancel="showGroupPicker = false, customerGroup = ''"
                  @confirm="onConfirmGroup"
                  default-index="0"
              />
            </van-popup>
          </van-cell-group>
        </van-col>
      </van-row>
      <van-row>
        <van-col span="24">
          <van-cell-group>
            <van-field
                readonly
                clickable
                label="会计年"
                :value="accountYear"
                placeholder="请选择"
                input-align="left"
                right-icon="tosend"
                @click="onClickYear()"
            />
            <van-popup v-model="showYearPicker" round position="bottom">
              <van-picker
                  show-toolbar
                  :columns="columnsYear"
                  @cancel="showYearPicker = false, accountYear = ''"
                  @confirm="onConfirmYear"
                  default-index="0"
              />
            </van-popup>
          </van-cell-group>
        </van-col>
      </van-row>
    </div>
    <div class="customer-payer-title">
      <van-row class="info-title">
        <van-col span="5">
          品类货量
        </van-col>
        <van-col span="15">
          <span v-if="tableAllTitle.length>4" style="color: #00fff5">
            (下方可左滑查看更多数据)
          </span>
        </van-col>
        <van-col v-if="tableAllTitle.length>4" span="4">
          <i class="el-icon-caret-left" @click="handleLeft()"></i>
          <i class="el-icon-caret-right" @click="handleRight()"></i>
        </van-col>
      </van-row>
    </div>
    <div class="customer-payer-content">
      <div class="customer-payer-content1"
           ref="tableRef">
        <el-table :data="tableAll"
                  style="width: 100%;margin:0 auto; border: 0px"
                  :show-header="true">
          <el-table-column v-if="tableAll.length>0" width="97" v-for="(value,key,index) in tableAllTitle" :key="index"
                           :label="value">
            {{ tableAll[0].split(',')[key] }}
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="customer-payer-title">
      <van-row class="info-title">表格信息</van-row>
    </div>
    <div class="customer-payer-content">
      <el-table
          :data="tableData"
          :show-header="true"
          :span-method="objectSpanMethod"
          show-summary
          style="width: 100%"
      >
        <el-table-column
            prop="customerName"
            label="客户名称"
            width="210"
        >
        </el-table-column>
        <el-table-column
            prop="weight"
            label="货量(万)"
        >
        </el-table-column>
        <el-table-column
            prop="name"
            label="品类"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {getPayerHlxy} from '@/api/customer';
import {getPayerHlpl} from '@/api/customer';
import {getPayerGroup} from '@/api/customer';
import {Toast} from "vant";

export default {
  name: "CustomerPayer",
  components: { },
  data() {
    return {
      customerName: '',
      customerGroup: '',
      accountYear: '',
      showGroupPicker: false,
      showYearPicker: false,
      columnsGroup: [],
      columnsYear: [],
      tableData: [],
      tableAll: [],
      tableAllTitle: ['名称'],
    }
  },
  watch: {
    //监听客户名头
    'customerName': function () {
      setTimeout(() => {
        this.init();
      }, 200);
    },
  },
  mounted() {
    getPayerGroup({}).then(res => {
      console.log(res)
      if (res.data.length > 0) {
        for (var i = 0; i < res.data.length; i++) {
          this.columnsGroup.push(res.data[i].customerGroup)
        }
      }
    }).catch(err => {
      console.log(err)
    })
    this.accountYear = new Date().getFullYear()
    this.columnsYear.push(new Date().getFullYear())
    this.columnsYear.push(new Date().getFullYear() - 1)
  },
  methods: {
    init() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 28000,
        loadingType: 'spinner'
      });
      this.tableData = []
      this.tableAllTitle = ['名称']
      this.tableAll = []
      getPayerHlxy({
        customerName: this.customerName,
        customerGroup: this.customerGroup,
        accountYear: this.accountYear
      }).then(res => {
        this.tableData = []
        console.log(res)
        if (res.data.length > 0) {
          for (var i = 0; i < res.data.length; i++) {
            if (res.data[i].weight != '0') {
              this.tableData.push(res.data[i])
            }
          }
        }
        this.tableAll = []
        getPayerHlpl({
          customerName: this.customerName,
          customerGroup: this.customerGroup,
          accountYear: this.accountYear
        }).then(res3 => {
          console.log(1)
          console.log(res3)
          this.tableAll = []
          var item = '货量,'
          if (res3.data.length > 0) {
            this.tableAllTitle = []
            this.tableAllTitle.push('名称')
            for (var t = 0; t < res3.data.length; t++) {
              this.tableAllTitle.push(res3.data[t].customerGroup)
              if (t == res3.data.length - 1) {
                item += res3.data[t].weight
              } else {
                item += res3.data[t].weight + ','
              }
            }
          }
          this.tableAll.push(item)
          Toast.success({
            message: '加载成功',
            duration: 800,
          });
          console.log(this.tableAllTitle)
          console.log(this.tableAll)
        }).catch(err => {
          console.log(err)
        })
        console.log(this.tableData)

      }).catch(err => {
        Toast.fail({
          message: '加载失败',
          duration: 800,
        });
        console.log(err)
      })
    },
    onClickGroup() {
      this.showGroupPicker = true
    },
    onConfirmGroup(picker, value, index) {
      console.log(picker, value, index);
      this.customerGroup = picker;
      this.showGroupPicker = false;
      setTimeout(() => {
        this.init();
      }, 200);
    },
    onClickYear() {
      this.showYearPicker = true
    },
    onConfirmYear(picker, value, index) {
      console.log(picker, value, index);
      this.accountYear = picker;
      this.showYearPicker = false;
      this.tableData = [];
      this.tableAllTitle = ['名称']
      this.tableAll = []
      setTimeout(() => {
        this.init();
      }, 200);

    },
    handleLeft(){
      this.$refs.tableRef.querySelector('.el-table__body-wrapper').scrollLeft -= 60;
    },
    handleRight(){
      this.$refs.tableRef.querySelector('.el-table__body-wrapper').scrollLeft += 60;
    },
    objectSpanMethod({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 0) {
        const _row = this.setTable(this.tableData).one[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
      }
      if (columnIndex === 1) {
        const _row = this.setTable(this.tableData).two[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
      }
    },
    setTable(tableData) {
      let spanOneArr = [],
          spanTwoArr = [],
          concatOne = 0,
          concatTwo = 0;
      tableData.forEach((item, index) => {
        if (index === 0) {
          spanOneArr.push(1);
          spanTwoArr.push(1);
        } else {
          if (item.customerName === tableData[index - 1].customerName) {
            //第一列需合并相同内容的判断条件
            spanOneArr[concatOne] += 1;
            spanOneArr.push(0);
          } else {
            spanOneArr.push(1);
            concatOne = index;
          }
          if (item.weight === tableData[index - 1].weight) {
            //第二列和需合并相同内容的判断条件
            spanTwoArr[concatTwo] += 1;
            spanTwoArr.push(0);
          } else {
            spanTwoArr.push(1);
            concatTwo = index;
          }
        }
      });
      return {
        one: spanOneArr,
        two: spanTwoArr
      };
    }
  }
}
</script>

<style scoped>

.customer-payer .info-title {
  background-color: #318EF5;
  padding: 10px 10px 10px;
  text-align: left;
  font-weight: 400;
  font-size: 15px;
  color: #FFFFFF;
  font-style: normal;
  letter-spacing: 0;
  line-height: 18px;
  text-decoration: none;
}

.customer-payer .customer-payer-content {
  text-align: center;
}


</style>


<style>
.customer-payer .customer-payer-content1 .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
  border-bottom: 0px solid #EBEEF5;
  overflow: scroll;
  width: 800px;
}
</style>